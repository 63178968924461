<template>
  <div>
    <div class="sub-header">
      <b-button
        :disabled="!selection.length > 0"
        variant="outline-primary"
        aria-label="Cancel Selected Order(s)"
        @click="showCancelModal()"
      >
        Cancel Selected
      </b-button>
    </div>

    <ajax-table
      ref="pendingVehicleFilingsTable"
      :busy.sync="pendingLoading"
      :table-definition="pendingTableDefinition"
      select-mode="multi"
      :selectable="true"
      :sticky-header="false"
      :responsive="false"
      empty-text="Currently, there are no pending orders on file."
      :selectable-function="canCancel"
      @selection-changed="selectionEvent"
      @loaded="onPendingLoad"
    >
      <template slot="cell(company)" slot-scope="data">
        <div>{{ data.item.company.name }}</div>
      </template>
      <template slot="cell(filing_name)" slot-scope="data">
        <div class="text-capitalize">{{ data.item.product.filing_name }}</div>
      </template>
      <template slot="cell(year)" slot-scope="data">
        <div>{{ data.item.data.vehicle_year }}</div>
      </template>
      <template slot="cell(make)" slot-scope="data">
        <div>{{ data.item.data.vehicle_make }}</div>
      </template>
      <template slot="cell(model)" slot-scope="data">
        <div>{{ data.item.data.vehicle_model }}</div>
      </template>
      <template slot="cell(vin)" slot-scope="data">
        <div class="text-uppercase">{{ data.item.data.vin___hin }}</div>
      </template>
      <template #row-details="data">
        <vehicle-tracker
          v-if="vehicleStatuses.includes(data.item.status)"
          :status="data.item.status"
          :filing-name="data.item.product.filing_name"
          :estimate="formatEstimate(data.item)"
          @iconClick="iconClicked(data.item)"
        />
      </template>
      <template #cell(status)="data">
        <b-button
          v-if="vehicleStatuses.includes(data.item.status)"
          class="toggle-tracker text-capitalize"
          variant="link"
          aria-label="toggle tracker"
          @click="data.toggleDetails"
        >
          Tracker
          <b-icon-chevron-up v-if="data.detailsShowing" />
          <b-icon-chevron-down v-else />
        </b-button>
        <div v-else>
          {{ readableStatus(data.item.status) }}
        </div>
      </template>
    </ajax-table>

    <div v-show="completedCount" class="mt-5 mb-5">
      <b-card class="completed-orders">
        <b-button
          v-b-toggle.collapse-removed
          variant="link"
          aria-label="Completed Orders link"
          @click="showCompleted = !showCompleted"
        >
          <span>Completed Orders</span>
          <b-icon-chevron-up v-if="showCompleted" />
          <b-icon-chevron-down v-else />
        </b-button>
        <b-collapse id="collapse-removed">
          <ajax-table
            :busy.sync="completedLoading"
            :table-definition="completedTableDefinition"
            :sticky-header="false"
            :responsive="false"
            @loaded="onCompletedLoad"
          >
            <template slot="cell(filing_name)" slot-scope="data">
              <div class="text-capitalize">{{ data.item.product.filing_name }}</div>
            </template>
            <template slot="cell(year)" slot-scope="data">
              <div>{{ data.item.data.vehicle_year }}</div>
            </template>
            <template slot="cell(make)" slot-scope="data">
              <div>{{ data.item.data.vehicle_make }}</div>
            </template>
            <template slot="cell(model)" slot-scope="data">
              <div>{{ data.item.data.vehicle_model }}</div>
            </template>
            <template slot="cell(vin)" slot-scope="data">
              <div class="text-uppercase">{{ data.item.data.vin___hin }}</div>
            </template>
            <template slot="cell(company)" slot-scope="data">
              <div>{{ data.item.company.name }}</div>
            </template>
          </ajax-table>
        </b-collapse>
      </b-card>
    </div>

    <cancel-filings-modal
      :bus="bus"
      @completed="filingsCancelCompleted"
    />

    <verify-address-modal
      v-if="!pendingLoading"
      :order-item="selectedItem"
      @closeVehicleOrderModal="closeOrderModal"
    />

    <required-document-upload
      @closeVehicleOrderModal="closeOrderModal"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Vue from 'vue'
import { VEHICLE_STATUSES } from '@/common/modules/constants'

export default {
  name: 'PendingVehicleOrders',
  components: {
    RequiredDocumentUpload: () => import('@/components/Vehicles/RequiredDocumentUpload.vue'),
    VerifyAddressModal:     () => import('@/components/Vehicles/VerifyAddressModal.vue'),
    AjaxTable:              () => import('@/components/shared/AjaxTable'),
    CancelFilingsModal:     () => import('@/components/CancelFilingsModal'),
    VehicleTracker:         () => import('@/components/Vehicles/VehicleTracker'),
  },
  data() {
    return {
      showModal: false,
      selection: [],
      bus: new Vue(),
      filingMethods: null,
      filingMethodIds: null,
      pendingLoading: true,
      completedLoading: false,
      completedCount: false,
      showCompleted: false,
      selectedItem: null,
    }
  },
  computed: {
    pendingTableDefinition() {
      return {
        columns: [
          { key: 'company', label: 'Company', sortable: true, sortAs: 'company.name' },
          { key: 'filing_name', label: 'Filing', sortable: true, sortAs: 'product.filing_name' },
          { key: 'year', label: 'Year', sortable: true, sortAs: 'data.vehicle_year' },
          { key: 'make', label: 'Make', sortable: true, sortAs: 'data.vehicle_make' },
          { key: 'model', label: 'Model', sortable: true, sortAs: 'data.vehicle_model' },
          { key: 'vin', label: 'Vin', sortable: true, sortAs: 'data.vin___hin' },
          { key: 'status', label: 'Status' },
        ],
        url: 'client/order_items/pending_vehicle_filings',
        defaultOrderDirection: 'desc',
        defaultOrderBy: 'updated_at',
      }
    },
    completedTableDefinition() {
      return {
        columns: [
          { key: 'filing_name', label: 'Filing', sortable: true, sortAs: 'product.filing_name' },
          { key: 'year', label: 'Year', sortable: true, sortAs: 'data.vehicle_year' },
          { key: 'make', label: 'Make', sortable: true, sortAs: 'data.vehicle_make' },
          { key: 'model', label: 'Model', sortable: true, sortAs: 'data.vehicle_model' },
          { key: 'vin', label: 'Vin', sortable: true, sortAs: 'data.vin___hin' },
          { key: 'company', label: 'Company', sortable: true, sortAs: 'company.name' },
        ],
        url: 'client/order_items/completed_vehicle_filings',
        defaultOrderDirection: 'desc',
        defaultOrderBy: 'created_at',
      }
    },
    vehicleStatuses() {
      return Object.values(VEHICLE_STATUSES)
    },
  },
  methods: {
    ...mapActions('orderItems', ['cancelOrderItems']),
    selectionEvent(selection) {
      this.selection = selection
    },
    canCancel(item) {
      return ['new', 'not-ready-to-file', 'held'].includes(item.status)
    },
    readableStatus(status) {
      return status.replace(/-/g, ' ')
    },
    async getFilingMethods() {
      const response = await this.$store.dispatch('filingMethods/fetchFilingMethods', {
        params: {
          id:  this.filingMethodIds,
        },
      })

      this.filingMethods = response.data
      this.pendingLoading = false
    },
    formatEstimate(item) {
      let filingMethod = null
      if (this.filingMethods) {
        filingMethod = this.filingMethods.find(method => method.id === item.product.filing_method.id)
      }

      if (filingMethod) {
        if ((filingMethod.filedIn.days === 0 || !filingMethod.filedIn.days)
          && filingMethod.filedIn.hours > 0) {
          return filingMethod.filedIn.hours + ' hours'
        } else {
          return filingMethod.filedIn.days + ' days'
        }
      } else if ((item.product.filing_method.filed_in.days === 0 || !item.product.filing_method.filed_in.days) &&
        item.product.filing_method.filed_in.hours > 0) {
        return item.product.filing_method.filed_in.hours + ' hours'
      } else {
        return item.product.filing_method.filed_in.days + ' days'
      }
    },
    async showCancelModal() {
      this.bus.$emit('openCancelFilings', this.selection)
    },
    filingsCancelCompleted() {
      this.$refs.pendingVehicleFilingsTable.reload()
    },
    onPendingLoad(orders) {
      this.pendingLoading = true
      orders.forEach(order => {
        if (order.status !== 'completed') {
          order._showDetails = true
        }
      })
      if (this.$route.params.orderItemId) {
        this.selectedItem = orders.find(obj => obj.id === this.$route.params.orderItemId)
      }
      this.filingMethodIds = orders.map(order => order.product.filing_method.id)
      this.getFilingMethods()
    },
    onCompletedLoad(orders) {
      this.completedCount = orders.length
    },
    iconClicked(orderItem) {
      this.selectedItem = orderItem
      if ([VEHICLE_STATUSES.NOT_READY_TO_FILE, VEHICLE_STATUSES.HELD].includes(orderItem.status)) {
        this.$router.replace(`/vehicle-registrations/pending-orders/${orderItem.id}/complete-docs`)
      }
      else if (orderItem.status === VEHICLE_STATUSES.WAITING_ON_ADDRESS) {
        this.$router.replace(`/vehicle-registrations/pending-orders/${orderItem.id}/verify-address`)
      }
    },
    closeOrderModal() {
      this.$router.replace('/vehicle-registrations/pending-orders')
    },
  },
}
</script>

<style lang="scss" scoped>
$primary: #283d96;
$primary-dark: darken($primary, 10%);
$disabled: #bec0c3;
$font: #212529;
$label-width: 25%;
$label-background: #f7f7fb;

@mixin typography($font-size: 1.0rem, $font-weight: 400, $color: $font) {
  font-size: $font-size;
  font-weight: $font-weight;
  color: $color;
}

@mixin btn-hover($hover-color: $primary-dark, $color: white) {
  border-color: $hover-color !important;
  background: $hover-color !important;
  color: $color !important;
}

@mixin btn-core($padding, $hover-color, $color) {
  @include typography($color: $color, $font-weight: 500);
  width: fit-content;
  border: 0.0625rem solid $primary;
  border-radius: 0.3125rem;
  padding: $padding;
  margin: 0;
  outline: 0;
  line-height: 1.1;

  &:hover:not([disabled]) {
    @include btn-hover();
  }
}

@mixin btn-default($primary-color, $hover-color, $padding: 0.5rem 1.0rem) {
  background: $primary-color;
  color: white;
  border-color: $primary-color;
  @include btn-core($padding, $hover-color, white);
}

@mixin btn-outline-default($primary-color, $hover-color, $padding: 0.5rem 1.0rem) {
  background: white;
  color: $primary-color;
  border-color: $primary-color;
  @include btn-core($padding, $hover-color, $primary-color);
}

button.btn-primary {
  @include btn-default(
    $primary-color: $primary,
    $hover-color: $primary-dark,
    $padding: 0 1.25rem
  );
}

button.btn-outline-primary {
  @include btn-outline-default(
    $primary-color: $primary,
    $hover-color: $primary-dark,
    $padding: 0 1rem
  );

  &:disabled, &:hover:disabled {
    border-color: $disabled !important;
    background-color: white !important;
    color: $disabled !important;
  }
}

.toggle-tracker {
  text-decoration: none;
  font-size: 0.9375rem;
  font-weight: 400;
  padding: 0;
}

@media (max-width: 735px) {
  :deep(.table.b-table.b-table-stacked) {
    tbody {
      tr.b-table-empty-row td {
        display: block;
      }

      > tr {
        [data-label] > div {
          width: calc(100% - $label-width);
        }

        > td {
          display: flex;
          padding: 0;

          &[data-label]::before {
            width: $label-width;
            text-align: left;
            background: $label-background;
            padding: 0 4px;
            min-height: 1.625rem;
          }
        }
      }
    }
  }
}

@media (min-width: 736px) {
  :deep(.table.b-table) {
    overflow: visible;

    td {
      padding-left: calc(0.25rem + 0.35em);
      vertical-align: middle;
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 -15px;
  padding: 1rem 30px 1rem 30px;
  border-bottom: 1px solid #dee2e6;

  > span {
    font-size: 2rem;
  }
}

.sub-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 1rem 0;

  button {
    margin-left: 1rem;
    text-wrap: nowrap;
    height: 37.6px;
  }
}

:deep(.table.b-table) {
  font-size: 0.9375rem;

  tr.b-table-has-details td {
    border: none;
    border-top: 1px solid #dee2e6;
  }

  th {
    font-weight: 600;
    border-bottom: none;
    background: $label-background;

    .custom-control-label::before,
    .custom-control-label::after {
      left: -1.9375rem;
    }

    &:last-child {
      min-width: 6rem;
    }
  }

  > thead > tr > th[aria-sort] {
    background-size: 0.45em .75em;
    background-position: left 0.35rem center !important;
  }

  tr {
    font-weight: 400;

    td {
      cursor: auto;

      .expandable-row {
        padding-left: 0;
      }
    }

    .custom-control-label::before,
    .custom-control-label::after {
      cursor: pointer;
    }
    .custom-control-input:checked ~ .custom-control-label::before,
    .custom-control-input:active ~ .custom-control-label::before,
    .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
      background-color: $primary !important;
      border-color: $primary !important;
    }

    .custom-control-input:disabled~.custom-control-label::after {
      cursor: default;
    }

    .custom-control-input:disabled~.custom-control-label::before {
      background-color: $disabled !important;
      border-color: $disabled !important;
      cursor: default;
    }
  }
}

.completed-orders {
  .card-body {
    padding: 0;
  }

  button {
    display: flex;
    justify-content: space-between;
    width: 100%;
    text-decoration: none;
    color: $font;
    font-weight: 500;
    padding: 1rem 0.5rem;

  }
}
</style>
