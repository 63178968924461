var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "sub-header" },
        [
          _c(
            "b-button",
            {
              attrs: {
                disabled: !_vm.selection.length > 0,
                variant: "outline-primary",
                "aria-label": "Cancel Selected Order(s)",
              },
              on: {
                click: function ($event) {
                  return _vm.showCancelModal()
                },
              },
            },
            [_vm._v("\n      Cancel Selected\n    ")]
          ),
        ],
        1
      ),
      _c("ajax-table", {
        ref: "pendingVehicleFilingsTable",
        attrs: {
          busy: _vm.pendingLoading,
          "table-definition": _vm.pendingTableDefinition,
          "select-mode": "multi",
          selectable: true,
          "sticky-header": false,
          responsive: false,
          "empty-text": "Currently, there are no pending orders on file.",
          "selectable-function": _vm.canCancel,
        },
        on: {
          "update:busy": function ($event) {
            _vm.pendingLoading = $event
          },
          "selection-changed": _vm.selectionEvent,
          loaded: _vm.onPendingLoad,
        },
        scopedSlots: _vm._u([
          {
            key: "cell(company)",
            fn: function (data) {
              return [_c("div", [_vm._v(_vm._s(data.item.company.name))])]
            },
          },
          {
            key: "cell(filing_name)",
            fn: function (data) {
              return [
                _c("div", { staticClass: "text-capitalize" }, [
                  _vm._v(_vm._s(data.item.product.filing_name)),
                ]),
              ]
            },
          },
          {
            key: "cell(year)",
            fn: function (data) {
              return [_c("div", [_vm._v(_vm._s(data.item.data.vehicle_year))])]
            },
          },
          {
            key: "cell(make)",
            fn: function (data) {
              return [_c("div", [_vm._v(_vm._s(data.item.data.vehicle_make))])]
            },
          },
          {
            key: "cell(model)",
            fn: function (data) {
              return [_c("div", [_vm._v(_vm._s(data.item.data.vehicle_model))])]
            },
          },
          {
            key: "cell(vin)",
            fn: function (data) {
              return [
                _c("div", { staticClass: "text-uppercase" }, [
                  _vm._v(_vm._s(data.item.data.vin___hin)),
                ]),
              ]
            },
          },
          {
            key: "row-details",
            fn: function (data) {
              return [
                _vm.vehicleStatuses.includes(data.item.status)
                  ? _c("vehicle-tracker", {
                      attrs: {
                        status: data.item.status,
                        "filing-name": data.item.product.filing_name,
                        estimate: _vm.formatEstimate(data.item),
                      },
                      on: {
                        iconClick: function ($event) {
                          return _vm.iconClicked(data.item)
                        },
                      },
                    })
                  : _vm._e(),
              ]
            },
          },
          {
            key: "cell(status)",
            fn: function (data) {
              return [
                _vm.vehicleStatuses.includes(data.item.status)
                  ? _c(
                      "b-button",
                      {
                        staticClass: "toggle-tracker text-capitalize",
                        attrs: {
                          variant: "link",
                          "aria-label": "toggle tracker",
                        },
                        on: { click: data.toggleDetails },
                      },
                      [
                        _vm._v("\n        Tracker\n        "),
                        data.detailsShowing
                          ? _c("b-icon-chevron-up")
                          : _c("b-icon-chevron-down"),
                      ],
                      1
                    )
                  : _c("div", [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.readableStatus(data.item.status)) +
                          "\n      "
                      ),
                    ]),
              ]
            },
          },
        ]),
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.completedCount,
              expression: "completedCount",
            },
          ],
          staticClass: "mt-5 mb-5",
        },
        [
          _c(
            "b-card",
            { staticClass: "completed-orders" },
            [
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle.collapse-removed",
                      modifiers: { "collapse-removed": true },
                    },
                  ],
                  attrs: {
                    variant: "link",
                    "aria-label": "Completed Orders link",
                  },
                  on: {
                    click: function ($event) {
                      _vm.showCompleted = !_vm.showCompleted
                    },
                  },
                },
                [
                  _c("span", [_vm._v("Completed Orders")]),
                  _vm.showCompleted
                    ? _c("b-icon-chevron-up")
                    : _c("b-icon-chevron-down"),
                ],
                1
              ),
              _c(
                "b-collapse",
                { attrs: { id: "collapse-removed" } },
                [
                  _c("ajax-table", {
                    attrs: {
                      busy: _vm.completedLoading,
                      "table-definition": _vm.completedTableDefinition,
                      "sticky-header": false,
                      responsive: false,
                    },
                    on: {
                      "update:busy": function ($event) {
                        _vm.completedLoading = $event
                      },
                      loaded: _vm.onCompletedLoad,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "cell(filing_name)",
                        fn: function (data) {
                          return [
                            _c("div", { staticClass: "text-capitalize" }, [
                              _vm._v(_vm._s(data.item.product.filing_name)),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "cell(year)",
                        fn: function (data) {
                          return [
                            _c("div", [
                              _vm._v(_vm._s(data.item.data.vehicle_year)),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "cell(make)",
                        fn: function (data) {
                          return [
                            _c("div", [
                              _vm._v(_vm._s(data.item.data.vehicle_make)),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "cell(model)",
                        fn: function (data) {
                          return [
                            _c("div", [
                              _vm._v(_vm._s(data.item.data.vehicle_model)),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "cell(vin)",
                        fn: function (data) {
                          return [
                            _c("div", { staticClass: "text-uppercase" }, [
                              _vm._v(_vm._s(data.item.data.vin___hin)),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "cell(company)",
                        fn: function (data) {
                          return [
                            _c("div", [_vm._v(_vm._s(data.item.company.name))]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("cancel-filings-modal", {
        attrs: { bus: _vm.bus },
        on: { completed: _vm.filingsCancelCompleted },
      }),
      !_vm.pendingLoading
        ? _c("verify-address-modal", {
            attrs: { "order-item": _vm.selectedItem },
            on: { closeVehicleOrderModal: _vm.closeOrderModal },
          })
        : _vm._e(),
      _c("required-document-upload", {
        on: { closeVehicleOrderModal: _vm.closeOrderModal },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }