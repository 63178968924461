var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.active
    ? _c(
        "b-modal",
        {
          attrs: {
            id: "add-vehicle-registration-modal",
            size: "lg",
            centered: "",
            "hide-footer": "",
          },
          on: { show: _vm.getRegistrationProducts, hide: _vm.hide },
        },
        [
          _c("template", { slot: "modal-title" }, [
            _c("h3", { staticStyle: { margin: "0", padding: "0" } }, [
              _vm._v("\n      " + _vm._s(_vm.title) + "\n    "),
            ]),
          ]),
          !_vm.selectedCompany
            ? _c(
                "div",
                [
                  _c("h3", [
                    _vm._v("Register Your Vehicle with Your Montana LLC"),
                  ]),
                  _c("p", [
                    _vm._v(
                      "Looking to register another vehicle under your Montana LLC? You've come to the right place!"
                    ),
                  ]),
                  _c("h5", [_vm._v("How It Works")]),
                  _c("ol", [
                    _c("li", [
                      _vm._v("Place your vehicle registration order."),
                    ]),
                    _c("li", [
                      _vm._v(
                        "After checkout, you'll receive an email with instructions on what documents to ship to us for filing with the state."
                      ),
                    ]),
                    _c("li", [
                      _vm._v(
                        "Once the state fulfills your order, we will ship your Montana license plates to the mailing address in your account."
                      ),
                    ]),
                  ]),
                  _c("h5", [_vm._v("Convenient Automatic Renewals")]),
                  _c("p", [
                    _vm._v(
                      "For your convenience, after your vehicle registration is complete, we will automatically enroll your vehicle in annual registration renewals if required."
                    ),
                  ]),
                  _c("h5", [
                    _vm._v(
                      "\n      Select a company for the vehicle to be registered to\n    "
                    ),
                  ]),
                  _c("company-select", {
                    staticClass: "mb-3",
                    attrs: { companies: _vm.MTCompanies },
                    on: { input: _vm.companySelected },
                  }),
                ],
                1
              )
            : !_vm.serviceToAdd
            ? _c(
                "div",
                [
                  _c(
                    "div",
                    { staticClass: "button-container" },
                    [
                      _c(
                        "b-link",
                        {
                          staticClass: "py-0",
                          attrs: {
                            variant: "link",
                            "aria-label": "Select a Different Company Link",
                          },
                          on: {
                            click: function ($event) {
                              _vm.selectedCompany = null
                            },
                          },
                        },
                        [
                          _c("fa-icon", { attrs: { icon: "chevron-left" } }),
                          _vm._v(
                            "\n        Back / Select a Different Company\n      "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("h3", [_vm._v("\n      Select your vehicle type\n    ")]),
                  _c("selection-cards", {
                    attrs: { items: _vm.vehicleFilingProducts() },
                    on: { chosen: _vm.selectedService },
                  }),
                  _c("div", { staticClass: "pt-2" }, [_vm._v("Step 1 of 4")]),
                ],
                1
              )
            : !_vm.continueToCheckout && !_vm.confirmOrder
            ? _c(
                "div",
                [
                  _c(
                    "div",
                    { staticClass: "button-container" },
                    [
                      _c(
                        "b-link",
                        {
                          staticClass: "py-0",
                          attrs: {
                            variant: "link",
                            "aria-label": "Select a Different Product link",
                          },
                          on: { click: _vm.selectDifferentProduct },
                        },
                        [
                          _c("fa-icon", { attrs: { icon: "chevron-left" } }),
                          _vm._v(
                            "\n        Back / Select a Different Product\n      "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("vehicle-registration-form-fields", {
                    attrs: {
                      "selected-registration": _vm.serviceToAdd,
                      "old-vehicle": _vm.vehicle,
                      loaded: _vm.loaded,
                    },
                    on: { "new-vehicle": _vm.newVehicle },
                  }),
                ],
                1
              )
            : _vm.showAddressVerification
            ? _c(
                "div",
                [
                  _c(
                    "div",
                    { staticClass: "button-container" },
                    [
                      _c(
                        "b-link",
                        {
                          staticClass: "py-0",
                          attrs: {
                            variant: "link",
                            "aria-label": "Modify Vehicle link",
                          },
                          on: { click: _vm.modifyVehicle },
                        },
                        [
                          _c("fa-icon", { attrs: { icon: "chevron-left" } }),
                          _vm._v("\n        Back / Modify Vehicle\n      "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("h3", [
                    _vm._v("\n      Select Your Mail Preferences\n    "),
                  ]),
                  _c("p", [
                    _vm._v(
                      "Confirm the address where you'd like us to mail your plates and registration once we receive them from the state."
                    ),
                  ]),
                  _c(
                    "b-form-radio-group",
                    {
                      model: {
                        value: _vm.addressVerificationNeeded,
                        callback: function ($$v) {
                          _vm.addressVerificationNeeded = $$v
                        },
                        expression: "addressVerificationNeeded",
                      },
                    },
                    [
                      _c(
                        "b-form-radio",
                        { staticClass: "mb-1", attrs: { value: false } },
                        [
                          _vm._v(
                            "Please mail my plates and registration to the address below."
                          ),
                        ]
                      ),
                      _c(
                        "b-form-radio",
                        { staticClass: "mb-3", attrs: { value: true } },
                        [
                          _vm._v(
                            "I will be traveling, so please contact me for an address before mailing my plates and registration."
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("validation-observer", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ invalid }) {
                            return [
                              _vm.addressVerificationNeeded
                                ? _c("div", [
                                    _c("h5", { staticClass: "my-3" }, [
                                      _vm._v(
                                        "We'll Contact You for Your Address"
                                      ),
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        "We will reach out to you for your address once your plates and registration are ready for mailing."
                                      ),
                                    ]),
                                  ])
                                : _c(
                                    "div",
                                    [
                                      _c("h5", { staticClass: "my-3" }, [
                                        _vm._v("Verify Your Address"),
                                      ]),
                                      _c("p", [
                                        _vm._v(
                                          "You can edit the address below. If you need to update it later, you can do so under the 'Pending Orders' section of Vehicle Registrations."
                                        ),
                                      ]),
                                      _c(
                                        "b-form-group",
                                        { attrs: { label: "Address" } },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              rules: "required|notKalispell",
                                              name: "Address",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ errors }) {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "text-danger",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          ),
                                                        ]
                                                      ),
                                                      _c("b-form-select", {
                                                        attrs: {
                                                          options:
                                                            _vm.addressOptions,
                                                          state: !errors[0],
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.selectedAddress,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.selectedAddress =
                                                              $$v
                                                          },
                                                          expression:
                                                            "selectedAddress",
                                                        },
                                                      }),
                                                      _c(
                                                        "b-button",
                                                        {
                                                          staticClass:
                                                            "manage-addresses",
                                                          attrs: {
                                                            variant: "link",
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.openAddressSettings,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n              Manage Addresses\n            "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              1224659745
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex justify-content-between",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex align-items-end" },
                                    [_vm._v("Step 3 of 4")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "pt-4" },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            variant: "primary",
                                            "aria-label": "Continue button",
                                            disabled: invalid,
                                          },
                                          on: {
                                            click:
                                              _vm.setAddressVerificationNeeded,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n            Continue\n          "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3066701685
                    ),
                  }),
                ],
                1
              )
            : _vm.continueToCheckout
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "button-container" },
                  [
                    _c(
                      "b-link",
                      {
                        staticClass: "py-0",
                        attrs: {
                          variant: "link",
                          "aria-label": "Change Mail Preferences link",
                        },
                        on: { click: _vm.modifyAddress },
                      },
                      [
                        _c("fa-icon", { attrs: { icon: "chevron-left" } }),
                        _vm._v(
                          "\n        Back / Change Mail Preferences\n      "
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("div", [
                  _c("h3", [_vm._v("Checkout")]),
                  _c("p", [_vm._v(_vm._s(_vm.vehicle_information))]),
                ]),
                _c(
                  "div",
                  [
                    _c("payment-method-options", {
                      attrs: {
                        "payment-method-errors": _vm.paymentMethodErrors,
                      },
                    }),
                    _c("cart", {
                      attrs: {
                        "processing-checkout": _vm.processingCheckout,
                        "button-text": "Checkout",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "pt-3" }, [_vm._v("Step 4 of 4")]),
              ])
            : _vm.confirmOrder
            ? _c(
                "div",
                [
                  _c(
                    "div",
                    { staticClass: "button-container" },
                    [
                      _c(
                        "b-link",
                        {
                          staticClass: "py-0",
                          attrs: {
                            variant: "link",
                            "aria-label": "Add Another Vehicle link",
                          },
                          on: { click: _vm.resetForm },
                        },
                        [
                          _c("fa-icon", { attrs: { icon: "chevron-left" } }),
                          _vm._v(
                            "\n        Back / Add Another Vehicle\n      "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("vehicle-order-confirmation", {
                    attrs: { invoice: _vm.invoice },
                  }),
                ],
                1
              )
            : _vm._e(),
          !_vm.loaded
            ? _c(
                "div",
                { staticClass: "loader" },
                [_c("ct-centered-spinner")],
                1
              )
            : _vm._e(),
          _c("address-settings-modal", { attrs: { bus: _vm.bus } }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }